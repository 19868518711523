import { useAccountDrawer } from 'components/AccountDrawer'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { DiscordIcon, TelegramIcon, TwitterIcon, RassetIcon, YoutubeIcon } from 'nft/components/icons'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import * as styles from './style.css'
import { SOCIAL_LINK } from 'config/config'

const Footer = styled.div`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`
const BoxSocial = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  gap: 40px;
`

const Footerbar = () => {
  const navigate = useNavigate()

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  return (
    <>
      <Footer>
        <Box display="flex" height="full">
          <Box gap="12" className={styles.leftSideContainer}>
            <RassetIcon
              width="140"
              height="40"
              data-testid="uniswap-logo"
              className={styles.logo}
            />
            <Row className={styles.CopyRight}>Copyright © 2024 Rasset LLC. All rights reserved</Row>
          </Box>
          <BoxSocial className={styles.rightSideContainer}>
            <a href={SOCIAL_LINK.telegram} target='_blank'>
            <TelegramIcon width={32} height={30} />
            </a> 
            <a href={SOCIAL_LINK.discord} target='_blank'>
            <DiscordIcon width={32} height={30} />
            </a>
            <a href={SOCIAL_LINK.youtube} target='_blank'>
            <YoutubeIcon width={32} height={30} />
            </a>
            <a href={SOCIAL_LINK.x} target='_blank'>
            <TwitterIcon width={32} height={25} viewBox="0 0 40 40" />
            </a>
          </BoxSocial>
        </Box>
      </Footer>
    </>
  )
}

export default Footerbar
