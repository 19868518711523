//@ts-nocheck
import { UNIVERSAL_ROUTER_ADDRESS } from "@uniswap/universal-router-sdk";
import { PERMIT2_ADDRESS } from '@uniswap/permit2-sdk'
import { ChainId, Token } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import { ethers, providers } from 'ethers'

export const CHAIN_ID= ChainId.POLYGON;
export const CHAIN_SUPPORT=[ChainId.POLYGON]
export const ROUTER_ADDRESS = { //universal router
  //137: "0xa2F0b3b855193BfE61294a034233177E513E2dfb",
  137:"0x8e55a4f6ffd147ff2f58b6826f00225599dca5f0"
};

export const WMATIC="0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
export const PERMIT_ADDRESS =  "0x000000000022D473030F116dDEE9F6B43aC78BA3";

export const FACTORY_V2_ADDRESS={
  [CHAIN_ID]:'0x7aB048a4B18d57Fb1398c55AeaAbD550dbeEB060'
} as any

export const FACTORY_V3_ADDRESS={
  [CHAIN_ID]:'0xa600FDc8c0521b8f598C0E1537Ce462F0d302356'
} as any

export const QUOTER_V2_ADDRESS={
  [CHAIN_ID]:'0x20971804940c31C83addAd3E281A33Bc7645470B'
}

export const TICKLENS_ADDRESS={
  [CHAIN_ID]:'0xB3813F376CbB3A526734A9448E7D4a18C68A4f1E'
} as any

export const POSITON_MANAGER={
  [CHAIN_ID]:'0x71879E34F59785BFfEA9461f1381D83E54dfeED2'
} as any

export const V3_MIGRATOR={
  [CHAIN_ID]:'0x363Beaf7e67b24c63ED6A889DAEc11a251ba4390'
}

export const SWAP_ROUTER_V2={
  [CHAIN_ID]:'0xAf0e733161EFd1da21629064714d2420b179A616'
} as any


export const MUTICALL={
  [CHAIN_ID]:'0xc476568278417007E50AD0EB5a07565538aeD800',
  1:"0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696"
}

export const SWAP_ROUTER_02={
  [CHAIN_ID]:'0x26FEb92DDa75b760F648852Cc59c0BFFCF853ade'
}





export function REPLACE_UNIVERSAL_ROUTER_ADDRESS(chainId: number){
  if (ROUTER_ADDRESS[chainId] ) {
    return ROUTER_ADDRESS[chainId];
  }
  return UNIVERSAL_ROUTER_ADDRESS(chainId);
}


export function REPLACE_PERMIT2_ADDRESS ( ){

 if (PERMIT_ADDRESS ) {
    return PERMIT_ADDRESS;
  }
  return PERMIT2_ADDRESS;
}


// Provider Functions

export const BACKEND_URL="https://api.kmax4ever.io.vn/v1/"
//export const BACKEND_URL="http://localhost:3001/v1/"
export const DEFAULT_LIST=[
  new Token(CHAIN_ID,`0x863e4f5ba5c4e6c9368e4b0f5b34886151ae77e6`,18,"k10","k10") as any
]

export const BACKEND_RASSET="https://rasset-staging-api-ipwbq.ondigitalocean.app/publics/tokens"

export const DEFAULT_PORTFOLIO_TOKEN={
  chain:"POLYGON",
  address:"0x0000000000000000000000000000000000001010",
  symbol:"MATIC",
  name:"Polygon",
  logoUrl :"https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png",
  standard: "NATIVE",
  decimals:18,
  chainId:137,
  priceChange:1,
  usdPrice:1,
}


export const DEFAULT_BASE_CURRENCY_LITE_MODE= new Token (CHAIN_ID,"0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",6,"USDC",'USD Coin')


export const RPC_LIST=[
`https://1rpc.io/matic`,
'https://rpc-mainnet.matic.quiknode.pro',
'https://polygon-rpc.com',
'https://polygon.blockpi.network/v1/rpc/public',
'https://rpc.ankr.com/polygon'
];


export const DEFAULT_TOKEN_IMAGE_URL={
  [CHAIN_ID]:'https://polygonscan.com/assets/poly/images/svg/empty-token.svg'
}

export const DUMMY_TOKENS_FROM_API = [
  //remove later
  {
    tokenContractAddress: "0x9113f17D511544e3E32162975f90Ca723f5f2C36",
    tokenSymbol: "A11",
    name: "A11",
    logoURI: "",
    standard: "NATIVE",
    decimals: 18,
    priceChange: 1,
    usdPrice: 0.0005,
    chainId: 137,
    chain: "POLYGON",
    assets: {
      assetName: "A11",
      assets: {
        assetName: "Vinhomes Ocean Park - S2.062110",
        assetType: {
          _id: "656da65a746a6ad688c0295b",
          typeName: "RealEstate",
          typeKey: "RealEstate",
          contractAddress: "0xee57e836bbd7cb6e73c475b87ada50c5929a811b",
        },
        nftId: 4,
        mainImage:
          "https://storage.googleapis.com/digital-platform/vho.old/VH_OceanPark/Sapphire1/S1.01/Apartment/PIM/Dong_Bac/2PN+1(2Toilet)/2PN+1(2Toilet)_1_large.jpg",
      },
    },
  },
] as any;
export const DEFAULT_LIQUID_CHART = [
  { activeLiquidity: 1, price0: 1 },
  { activeLiquidity: 99999999, price0: 99999999 },
];

export const RASSET_LINK={
  financial:"https://financial-stg.rasset.io/vi",
  depository:" https://depository-stg.rasset.io/en/shares",
  blogs:""
}

export const SOCIAL_LINK={
  x:"https://twitter.com/rAsset_io",
  youtube:"https://www.youtube.com/@rAsset_io",
  telegram:"",
  discord:""
}

export const RASSET_IO_URL='https://stg.rasset.io/en/assets/'

export const RAINBOW_CONFIG= {
  appName: 'AMM',
  projectId: 'b8b2d092cbd805db4677cd8b354aac00',
  chains: [
  // mainnet,
   // polygon,
    //optimism,
    //arbitrum,
    //base,
  ],
  ssr: true,
};
