import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Protocol } from '@uniswap/router-sdk'
import { sendAnalyticsEvent } from 'analytics'
import { isUniswapXSupportedChain } from 'constants/chains'
import ms from 'ms'
import { logSwapQuoteRequest } from 'tracing/swapFlowLoggers'
import { trace } from 'tracing/trace'
import { Token } from '@uniswap/sdk-core'
import {
  GetQuoteArgs,
  INTERNAL_ROUTER_PREFERENCE_PRICE,
  QuoteMethod,
  QuoteState,
  RouterPreference,
  RoutingConfig,
  TradeResult,
  URAQuoteResponse,
  URAQuoteType,
} from './types'
import { isExactInput, transformQuoteToTrade } from './utils'
import { BACKEND_URL } from 'config/config'
import { isMobile } from 'utils/userAgent'

const UNISWAP_API_URL = process.env.REACT_APP_UNISWAP_API_URL
if (UNISWAP_API_URL === undefined) {
  throw new Error(`UNISWAP_API_URL must be a defined environment variable`)
}

const CLIENT_PARAMS = {
  protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED],
}

const protocols: Protocol[] = [Protocol.V2, Protocol.V3, Protocol.MIXED]

// routing API quote query params: https://github.com/Uniswap/routing-api/blob/main/lib/handlers/quote/schema/quote-schema.ts
const DEFAULT_QUERY_PARAMS = {
  protocols,
  // this should be removed once BE fixes issue where enableUniversalRouter is required for fees to work
  enableUniversalRouter: true,
}

function getQuoteLatencyMeasure(mark: PerformanceMark): PerformanceMeasure {
  performance.mark('quote-fetch-end')
  return performance.measure('quote-fetch-latency', mark.name, 'quote-fetch-end')
}

function getRoutingAPIConfig(args: GetQuoteArgs): RoutingConfig {
  const { account, tokenInChainId, uniswapXForceSyntheticQuotes, routerPreference } = args

  const uniswapx = {
    useSyntheticQuotes: uniswapXForceSyntheticQuotes,
    // Protocol supports swap+send to different destination address, but
    // for now recipient === swapper
    recipient: account,
    swapper: account,
    routingType: URAQuoteType.DUTCH_LIMIT,
  }

  const classic = {
    ...DEFAULT_QUERY_PARAMS,
    routingType: URAQuoteType.CLASSIC,
    recipient: account,
    enableFeeOnTransferFeeFetching: true,
  }

  if (
    // If the user has opted out of UniswapX during the opt-out transition period, we should respect that preference and only request classic quotes.
    routerPreference === RouterPreference.API ||
    routerPreference === INTERNAL_ROUTER_PREFERENCE_PRICE ||
    !isUniswapXSupportedChain(tokenInChainId)
  ) {
    return [classic]
  }

  return [uniswapx, classic]
}

export const routingApi = createApi({
  reducerPath: 'routingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: UNISWAP_API_URL,
  }),
  endpoints: (build) => ({
    getQuote: build.query<TradeResult, GetQuoteArgs>({
      async queryFn(args, _api, _extraOptions, fetch):Promise<any>  {
        logSwapQuoteRequest(args.tokenInChainId, args.routerPreference, false)
        const quoteStartMark = performance.mark(`quote-fetch-start-${Date.now()}`)
        try {
          const {
            tokenInAddress: tokenIn,
            tokenInChainId,
            tokenOutAddress: tokenOut,
            tokenOutChainId,
            amount,
            tradeType,
            sendPortionEnabled,
            tokenInSymbol,
            tokenInDecimals,
            tokenOutSymbol,
            tokenOutDecimals

          } = args

          // const requestBody = {
          //   tokenInChainId,
          //   tokenIn,
          //   tokenOutChainId,
          //   tokenOut,
          //   amount,
          //   sendPortionEnabled,
          //   type: isExactInput(tradeType) ? 'EXACT_INPUT' : 'EXACT_OUTPUT',
          //   intent: args.routerPreference === INTERNAL_ROUTER_PREFERENCE_PRICE ? 'pricing' : undefined,
          //   configs: getRoutingAPIConfig(args),
          // }

          const token0= {
            address:  ["MATIC","WMATIC"].includes(tokenInSymbol as any)  ? "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270" :tokenIn,
            chainId: 137,
            decimals: tokenInDecimals,
            name: tokenInSymbol,
            symbol: tokenInSymbol,
          }

          const token1=  {
            address:  ["MATIC","WMATIC"].includes(tokenOutSymbol as any) ? "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270" :tokenOut,
            chainId: 137,
            decimals: tokenOutDecimals,
            name: tokenOutSymbol,
            symbol: tokenOutSymbol,
          }
          if(!amount || +amount==0)
          {
            return;
          }
            
          const quoteData = await fetch({
            method: 'post',
            url: BACKEND_URL +"quote",
            body:{
              token0,
              token1,
              amountIn:amount
            }
          })
          const uraQuoteResponse = quoteData.data as URAQuoteResponse
          const tradeResult = await transformQuoteToTrade(args, uraQuoteResponse, QuoteMethod.ROUTING_API)
          
          return { data: { ...tradeResult, latencyMs: !isMobile ?getQuoteLatencyMeasure(quoteStartMark).duration:10 } }
        } catch (error: any) {
          console.warn(
            `GetQuote failed on Unified Routing API, falling back to client: ${
              error?.message ?? error?.detail ?? error
            }`
          )
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: error?.detail ?? error?.message ?? error,
            },
          };
        }

        // try {
        //   const { getRouter, getClientSideQuote } = await import('lib/hooks/routing/clientSideSmartOrderRouter')
        //   const router = getRouter(args.tokenInChainId)
        //   const quoteResult = await getClientSideQuote(args, router, CLIENT_PARAMS)
        //   if (quoteResult.state === QuoteState.SUCCESS) {
        //     const trade = await transformQuoteToTrade(args, quoteResult.data, QuoteMethod.CLIENT_SIDE_FALLBACK)
        //     return {
        //       data: { ...trade, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration },
        //     }
        //   } else {
        //     return { data: { ...quoteResult, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration } }
        //   }
        // } catch (error: any) {
        //   console.warn(`GetQuote failed on client: ${error}`)
        //   return {
        //     error: { status: 'CUSTOM_ERROR', error: error?.detail ?? error?.message ?? error },
        //   }
        // }
      },
      keepUnusedDataFor: ms(`10s`),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuoteQuery } = routingApi
export const useGetQuoteQueryState = routingApi.endpoints.getQuote.useQueryState
