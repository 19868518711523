import axios from "axios";
export const callApi = async (url: string, method:string, data?:any) => {
  try {
    const rs = await axios({
      method: method,
      url: url,
      timeout: 5000,
      data,
    });

    return rs;
  } catch (error) {
    console.log(`callApi`,error);
    return null;
  }
};
