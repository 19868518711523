
// @ts-nocheck

const ecr20Abi = require("../abis/erc20.json");
import {getMuticall} from "./muticall3"
const aggregatedObjBalanceOf = (token:string, wallet:string) => {
  const aggregatedObj = {
    reference: token,
    contractAddress: token,
    abi: ecr20Abi,
    calls: [
      {
        methodName: "balanceOf",
        methodParameters: [wallet],
      },
    ],
  };
  return aggregatedObj;
};

export async function getTokensBalanceMuticall(tokenAddresss = [], wallet:string) {
  const aggregatedCall = [];

  for (const token of tokenAddresss) {
    aggregatedCall.push(aggregatedObjBalanceOf(token, wallet));
  }
  const multicall = getMuticall();
  const { results } = await multicall.call(aggregatedCall);
  const rs = {};
  for (const reference in results) {
    const { returnValues } = results[reference].callsReturnContext[0];
    const balance = returnValues[0]?.hex? BigInt(returnValues[0].hex).toString() :0;
    rs[reference] = balance;
  }
  return rs;
}
