// @ts-nocheck
import {
  BACKEND_RASSET,
  BACKEND_URL,
  CHAIN_ID,
  DEFAULT_TOKEN_IMAGE_URL,
  DUMMY_TOKENS_FROM_API,
  RPC_LIST,
} from "config/config";
import { log } from "console";
import { ethers, BigNumber } from "ethers";
import ecr20Abi from "../abis/erc20.json";
import { callApi } from "./callApi";
const accounting = require("accounting-js");

export const toWei = (value: string, decimal?: number) => {
  return ethers.utils.parseUnits(value, decimal || "ether");
};

const randomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const fromWei = (value: string, decimal?: number) => {
  return ethers.utils.formatUnits(value, decimal || "ether");
};
export const getProvider = () => {
  const rpc = RPC_LIST[randomInt(0, RPC_LIST.length - 1)];
  const provider = new ethers.providers.JsonRpcProvider(rpc);
  return provider;
};

export const getBalance = async (account: string) => {
  const provider = getProvider();
  const balance = await provider.getBalance(account);
  return balance.toString();
};

export const getTokenBalance = async (
  account: string,
  tokenAddress: string
) => {
  try {
    const provider = getProvider();
    const erc20 = new ethers.Contract(tokenAddress, ecr20Abi, provider);
    const balance = await erc20.balanceOf(account);
    return balance.toString();
  } catch (error) {}
};

export const getPortfolioTokenData = (
  token,
  balance,
  usdAmount = 0,
  priceChange = 0
) => {
  const id = token.address;
  const defaultTokenData = {
    denominatedValue: {
      currency: "USD",
      id,
      value: usdAmount,
    },
    id,
    quantity: balance,
    token: {
      ...token,
      id,
      // market: {
      //   price: 1,
      //   pricePercentChange: priceChange,
      // },
    },
    tokenProjectMarket: {
      id,
      pricePercentChange: {
        id,
        value: priceChange,
      },
      tokenProject: {
        id,
        logoUrl: token.logoUrl,
        isSpam: false,
      },
    },
  };
  return defaultTokenData;
};

export const formatNumber = (_number: number) => {
  return accounting.formatNumber(_number);
};

export const getTokensRasset = async (
  limit = 10,
  offset = 0,
  search? = "",
  isGetMarketDetail? = true
) => {
  let url = `${BACKEND_RASSET}?offset=${offset}&limit=${limit}`;
  if (search) {
    url += `&search=${search}`;
  }
  const rs = { total: 0, data: [] };
  try {
    const response = await callApi(url, "get");
    if (response?.data) {
      rs.total = response?.data?.total;
      rs.data = response?.data?.data;
    }
  } catch (error) {
    console.log("errr", error);
  }
  let response;
  if (isGetMarketDetail) {
    const tokens = rs.data.map((i) => i.tokenContractAddress);
    response = await callApi(`${BACKEND_URL}getTokenData`, "post", {
      tokens,
    });
  }

  for (const token of rs.data) {
    token["market"] = {};
    token[`address`] = token.tokenContractAddress.toLowerCase();
    token["symbol"] = token.tokenSymbol;
    token["name"] = token.tokenName;
    token[`logoURI`] =
      token?.assets?.mainImage || DEFAULT_TOKEN_IMAGE_URL[CHAIN_ID];
    token[`isToken`] = true;
    token[`isNative`] = false;
    token[`chainId`] = CHAIN_ID;
    token[`chain`] = "POLYGON";

    if (isGetMarketDetail) {
      const { price24h, priceChange, highgest24h, lowest24h, volume24h } =
        response?.data[token.address.toLowerCase()] || {};

      const marketDetail = {
        price: response?.data[token.address]?.price || 0,
        pricePercentChange: priceChange || 0,
        volume: volume24h || 0,
        priceHigh24h: highgest24h || 0,
        priceLow24h: lowest24h || 0,
        totalValueLocked: 0,
      };

      if (token.stakedAddress) {
        const balance = await getTokenBalance(
          token.stakedAddress,
          token.address
        );
        marketDetail["totalValueLocked"] += balance ? +fromWei(balance) : 0;
      }
      if (token.uniV3Pool) {
        const balance = await getTokenBalance(token.uniV3Pool, token.address);
        marketDetail["totalValueLocked"] += balance ? +fromWei(balance) : 0;
      }
      token.market = { ...token.market, ...marketDetail };
    }
  }
  return rs;
};

export const handleFavouriteList = (symbol: string) => {
  const favourites = localStorage.getItem(
    "redux_localstorage_favourites_tokens"
  );

  if (!favourites) {
    localStorage.setItem(
      `redux_localstorage_favourites_tokens`,
      JSON.stringify({ symbols: [symbol] })
    );
    return;
  }

  const newFavourites = JSON.parse(favourites);
  const idx = newFavourites.symbols.findIndex((i) => i == symbol);
  if (idx == -1) {
    newFavourites.symbols.push(symbol);
  } else {
    newFavourites.symbols.splice(idx, 1);
  }
  localStorage.setItem(
    `redux_localstorage_favourites_tokens`,
    JSON.stringify(newFavourites)
  );
};

export const getFavourites = () => {
  const favourites = localStorage.getItem(
    "redux_localstorage_favourites_tokens"
  );
  if (!favourites) return [];
  const rs = JSON.parse(favourites);
  return rs.symbols;
};

export const isInFavouriteList = (symbol: string) => {
  const list = getFavourites();
  const rs = list.includes(symbol);
  return rs;
};

export const waitMs = (msDuration: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(null);
    }, msDuration);
  });
};
