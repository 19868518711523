// @ts-nocheck
import {
  BrowserEvent,
  InterfaceElementName,
  SharedEventName,
} from "@uniswap/analytics-events";
import { TraceEvent } from "analytics";
import Row from "components/Row";
import { DeltaArrow } from "components/Tokens/TokenDetails/Delta";
import { useInfoExplorePageEnabled } from "featureFlags/flags/infoExplore";
import { TokenBalance } from "graphql/data/__generated__/types-and-hooks";
import {
  getTokenDetailsURL,
  gqlToCurrency,
  logSentryErrorForUnsupportedChain,
} from "graphql/data/util";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { EllipsisStyle, ThemedText } from "theme/components";
import { NumberType, useFormatter } from "utils/formatNumbers";
import { getTokensBalanceMuticall } from "utils/getTokensBalance";
import {
  fromWei,
  getBalance,
  getPortfolioTokenData,
  getTokensRasset,
} from "utils/helper";

import { DEFAULT_PORTFOLIO_TOKEN } from "../../../../config/config";
import { useToggleAccountDrawer } from "../..";
import { hideSmallBalancesAtom } from "../../SmallBalanceToggle";
import { PortfolioLogo } from "../PortfolioLogo";
import PortfolioRow, { PortfolioTabWrapper } from "../PortfolioRow";

export default function Tokens({ account }: { account: string }) {
  const toggleWalletDrawer = useToggleAccountDrawer();
  const hideSmallBalances = useAtomValue(hideSmallBalancesAtom);
  const [showHiddenTokens, setShowHiddenTokens] = useState(false);
  // const { data } = useCachedPortfolioBalancesQuery({ account })
  // const tokenBalances = data?.portfolios?.[0].tokenBalances as TokenBalance[] | undefined

  // const { visibleTokens, hiddenTokens } = useMemo(
  //   () => splitHiddenTokens(tokenBalances ?? [], { hideSmallBalances }),
  //   [hideSmallBalances, tokenBalances]
  // )
  const [visibleTokens, setVisibeTokens] = useState([] as any);

  const [balance, setBalance] = useState(0);
  //paging
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);

  const [usdAmount, setUsdAmount] = useState(0);
  const [priceChange, setPriceChange] = useState(0);
  const getBalanceData = async () => {
    if (!account) {
      return;
    }
    const balance = await getBalance(account);
    setBalance(+fromWei(balance));
  };

  const getListToken = async () => {
    const { data, total } = await getTokensRasset(limit, offset, "", true);
    const tokens = data;
    const visibleTokens = [];
    const tokenAddress = tokens.map((i) => i.address);
    const balanceToken = await getTokensBalanceMuticall(tokenAddress, account);
    for (const token of tokens) {
      const balance = fromWei(balanceToken[token.address], token.decimals);
      const usdtAmount = (token?.market?.price || 0) * balance;
      visibleTokens.push(
        getPortfolioTokenData(
          token,
          balance,
          usdtAmount,
          token?.market?.pricePercentChange
        )
      );
    }
    setVisibeTokens(visibleTokens);
  };

  useEffect(() => {
    getBalanceData();
    getListToken();
  }, []);

  // if (!data) {
  //   return <PortfolioSkeleton />
  // }

  // if (tokenBalances?.length === 0) {
  //   // TODO: consider launching moonpay here instead of just closing the drawer
  //   return <EmptyWalletModule type="token" onNavigateClick={toggleWalletDrawer} />
  // }

  // const defaultTokenData = getPortfolioTokenData(
  //   DEFAULT_PORTFOLIO_TOKEN,
  //   balance,
  //   usdAmount,
  //   priceChange
  // );
  const toggleHiddenTokens = () =>
    setShowHiddenTokens((showHiddenTokens) => !showHiddenTokens);

  return (
    <PortfolioTabWrapper>
      {/* <TokenRow
        key={defaultTokenData.id}
        {...defaultTokenData}
        token={defaultTokenData.token as any}
      /> */}

      {visibleTokens.map(
        (tokenBalance: any) =>
          tokenBalance.token && (
            <TokenRow
              key={tokenBalance.id}
              {...tokenBalance}
              token={tokenBalance.token}
            />
          )
      )}
      {/* <ExpandoRow isExpanded={showHiddenTokens} toggle={toggleHiddenTokens} numItems={hiddenTokens.length}>
        {hiddenTokens.map(
          (tokenBalance) =>
            tokenBalance.token && <TokenRow key={tokenBalance.id} {...tokenBalance} token={tokenBalance.token} />
        )}
      </ExpandoRow> */}
    </PortfolioTabWrapper>
  );
}

const TokenBalanceText = styled(ThemedText.BodySecondary)`
  ${EllipsisStyle}
`;
const TokenNameText = styled(ThemedText.SubHeader)`
  ${EllipsisStyle}
`;

type PortfolioToken = NonNullable<TokenBalance["token"]>;

function TokenRow({
  token,
  quantity,
  denominatedValue,
  tokenProjectMarket,
}: any & { token: any }) {
  const { formatDelta } = useFormatter();

  const percentChange = token?.market?.pricePercentChange;

  const navigate = useNavigate();
  const toggleWalletDrawer = useToggleAccountDrawer();
  const isInfoExplorePageEnabled = useInfoExplorePageEnabled();

  const navigateToTokenDetails = useCallback(async () => {
    navigate(getTokenDetailsURL({ ...token, isInfoExplorePageEnabled }));
    toggleWalletDrawer();
  }, [navigate, token, isInfoExplorePageEnabled, toggleWalletDrawer]);
  const { formatNumber } = useFormatter();

  const currency = gqlToCurrency(token);
  if (!currency) {
    logSentryErrorForUnsupportedChain({
      extras: { token },
      errorMessage:
        "Token from unsupported chain received from Mini Portfolio Token Balance Query",
    });
    return null;
  }

  return (
    <TraceEvent
      events={[BrowserEvent.onClick]}
      name={SharedEventName.ELEMENT_CLICKED}
      element={InterfaceElementName.MINI_PORTFOLIO_TOKEN_ROW}
      properties={{
        chain_id: currency.chainId,
        token_name: token?.name,
        address: token?.address,
      }}
    >
      <PortfolioRow
        left={
          <PortfolioLogo
            chainId={currency.chainId}
            currencies={[currency]}
            size="40px"
            logoURI={token.logoURI}
          />
        }
        title={<TokenNameText>{token?.name}</TokenNameText>}
        descriptor={
          <TokenBalanceText>
            {formatNumber({
              input: quantity,
              type: NumberType.TokenNonTx,
            })}{" "}
            {token?.symbol}
          </TokenBalanceText>
        }
        onClick={navigateToTokenDetails}
        right={
          denominatedValue && (
            <>
              <ThemedText.SubHeader>
                {formatNumber({
                  input: token?.market?.price,
                  type: NumberType.FiatTokenPrice,
                })}
              </ThemedText.SubHeader>
              <Row justify="flex-end">
                <DeltaArrow delta={percentChange} />
                <ThemedText.BodySecondary>
                  {formatDelta(percentChange)}
                </ThemedText.BodySecondary>
              </Row>
            </>
          )
        }
      />
    </TraceEvent>
  );
}
